// CookieBanner.js
import React, { useState, useEffect } from 'react';
import './CookieBanner.css';

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) setShowBanner(true);
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowBanner(false);
  };

  return (
    showBanner && (
      <div className="cookieOverlay">
        <div className="cookieBanner">
          <h2 className="cookieTitle">Cookies</h2>
          <p className="cookieMessage">
            At Amica, we use cookies to give you the best experience on our website and to ensure any communications from Amica are relevant to you. For more details see our{' '}
            <a href="/PrivacyPolicy" target="_blank" rel="noopener noreferrer" className="policyLink">
              privacy policy
            </a>.<br />By clicking Accept, you agree to our use of all cookies.
          </p>
          <button onClick={handleAccept} className="acceptButton">
            Accept
          </button>
        </div>
      </div>
    )
  );
};

export default CookieBanner;
