import React from 'react';
import './PrivacyPolicy.css'; // Import the CSS file for styling

const PrivacyPolicy = () => (
    <div className="policyContainerOuter">
        <div className="policyContainer">
            <h1 className="policyTitle">Amica Privacy Policy</h1>
            <p className="policyParagraph">
            This website is operated by Amica Stays, a small business managing two properties in Belfast, UK. We are committed to protecting your personal information in accordance with the Data Protection Act 2018 and the EU General Data Protection Regulation (GDPR).
            </p>
            <h2 className="policySubtitle">Personal Information Collection</h2>
            <p className="policyParagraph">
            We collect your personal information when you book with us or subscribe to our updates. This information may include your name, email, and payment details, solely for managing reservations and enhancing your experience.
            </p>
            {/* Additional content here */}
        </div>
  </div>
);

export default PrivacyPolicy;
