const Images = {
  bella: {
    bella1: { full: require('./Bella/bella.jpeg') },
    bella2: { full: require('./Bella/bella2.jpeg') },
    bella3: { full: require('./Bella/bella3.jpeg') },
    bella4: { full: require('./Bella/Bella4.jpeg') },
    bella5: { full: require('./Bella/Bella5.jpeg') },
    bella6: { full: require('./Bella/Bella6.jpeg') },
    bella7: { full: require('./Bella/Bella7.jpeg') },
    bella8: { full: require('./Bella/Bella8.jpeg') },
    bella9: { full: require('./Bella/Bella9.jpeg') },
  },
  double: {
    double1: { full: require('./Double/DSC00006.jpg') },
    double2: { full: require('./Double/DSC00008.jpg') },
    double3: { full: require('./Double/DSC00023.jpg') },
    double4: { full: require('./Double/DSC00024.jpg') },
    double5: { full: require('./Double/DSC00025.jpg') },
    double6: { full: require('./Double/DSC00027.jpg') },
    double7: { full: require('./Double/DSC00032.jpg') },
    double8: { full: require('./Double/DSC00035.jpg') },
    double9: { full: require('./Double/DSC00037.jpg') },
    double10: { full: require('./Double/DSC00039.jpg') },
  },
  king: {
    king1: { full: require('./King/DSC09853.jpg') },
    king2: { full: require('./King/DSC09859.jpg') },
    king3: { full: require('./King/DSC09868.jpg') },
    king4: { full: require('./King/DSC09868-2.jpg') },
    king5: { full: require('./King/DSC09878.jpg') },
    king6: { full: require('./King/DSC09881.jpg') },
    king7: { full: require('./King/DSC09881-2.jpg') },
    king8: { full: require('./King/DSC09883.jpg') },
    king9: { full: require('./King/DSC09886.jpg') },
    king10: { full: require('./King/DSC09893.jpg') },
    king11: { full: require('./King/DSC09896.jpg') },
    king12: { full: require('./King/DSC09902.jpg') },
  },
  suite: {
    suite1: { full: require('./Suite/DSC00002.jpg') },
    suite2: { full: require('./Suite/DSC00061.jpg') },
    suite3: { full: require('./Suite/DSC09906.jpg') },
    suite4: { full: require('./Suite/DSC09911.jpg') },
    suite5: { full: require('./Suite/DSC09912.jpg') },
    suite6: { full: require('./Suite/DSC09925.jpg') },
    suite7: { full: require('./Suite/DSC09940.jpg') },
    suite8: { full: require('./Suite/DSC09949.jpg') },
    suite9: { full: require('./Suite/DSC09957.jpg') },
    suite10: { full: require('./Suite/DSC09961.jpg') },
    suite11: { full: require('./Suite/DSC09984.jpg') },
    suite12: { full: require('./Suite/DSC09998.jpg') },
    suite13: { full: require('./Suite/DSC09921.jpg') },
    suite14: { full: require('./Suite/DSC09928.jpg') },
    suite15: { full: require('./Suite/DSC09944.jpg') },
    suite16: { full: require('./Suite/DSC09951.jpg') },
    suite17: { full: require('./Suite/DSC09959.jpg') },
    suite18: { full: require('./Suite/DSC09982.jpg') },
    suite19: { full: require('./Suite/DSC09990.jpg') },
  }
};

export default Images;
