import React, { useState } from 'react';
import './amicaRose.css';
import image1 from './guesthousepics/Suite/DSC09928.jpg';
import image2 from './guesthousepics/Suite/DSC09906.jpg';
import image3 from './guesthousepics/Bella/Bella5.jpeg';
import image4 from './guesthousepics/Double/DSC00027.jpg';
import image5 from './guesthousepics/King/DSC09868-2.jpg';
import image6 from './guesthousepics/King/DSC09853.jpg';


const Carousel = ({ closeCarousel }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [image1, image2, image3, image4, image5, image6];

  const nextSlide = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleClickOutside = (event) => {
    if (event.target.id === 'carousel') {
      closeCarousel();
    }
  };

  return (
    <div id="carousel" className="amica-rose-carousel" onClick={handleClickOutside}>
      <button className="amica-rose-close-carousel" onClick={closeCarousel}>X</button>
      <div className="amica-rose-carousel-content">
        <img src={images[currentImageIndex]} alt={`Slide ${currentImageIndex}`} />
        <button className="carousel-control prev" onClick={prevSlide}>‹</button>
        <button className="carousel-control next" onClick={nextSlide}>›</button>
      </div>
    </div>
  );
};

export default Carousel;
