import React from 'react';
import { Link } from 'react-router-dom';
import styles from './home.module.css'; // Import CSS Modules

const Home = () => (
  <div className={styles.homeContainer}>
    <div className={styles.homeWrap}>
      <div className={styles.logoContainer}>
        <img src="/amica/amica.png" alt="Amica Stays Logo" className={styles.logo} />
      </div>
      <h1 className={styles.title}>Welcome to Belfast</h1>
      <p className={styles.description}>Your home away.</p>
      <div className={styles.imagesContainer}>
        <div className={styles.propertyWrap}>
          <Link to="/amicaguesthouse/overview">
            <img src="/amica/amicaguesthouse.png" alt="Amica Guesthouse" className={styles.propertyImage} />
            <div className={styles.propertyName}>Amica Guesthouse</div>
          </Link>
        </div>
        <div className={styles.propertyWrap}>
          <Link to="/amicarose/overview">
            <img src="/amica/amicarose.JPG" alt="Amica Rose" className={styles.propertyImage} />
            <div className={styles.propertyName}>Amica Rose</div>
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default Home;
