import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/Home/Home';
import AmicaGuesthouse from './components/AmicaGuesthouse/Overview';
import Rooms from './components/AmicaGuesthouse/Rooms';
import GuestMap from './components/AmicaGuesthouse/Map';
import GuestContacts from './components/AmicaGuesthouse/Contacts';
import AmicaRose from './components/AmicaRose/Overview';
import Gallery from './components/AmicaRose/Gallery';
import Map from './components/AmicaRose/Map';
import Contacts from './components/AmicaRose/Contacts';
import CookieBanner from './CookieBanner';
import PrivacyPolicy from './PrivacyPolicy';

const AppContent = () => {
  const location = useLocation();

  const showCookieBanner = location.pathname !== '/PrivacyPolicy';

  return (
    <>
      {showCookieBanner && <CookieBanner />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/amicaguesthouse/overview" element={<AmicaGuesthouse />} />
        <Route path="/amicaguesthouse/rooms" element={<Rooms />} />
        <Route path="/amicaguesthouse/map" element={<GuestMap />} />
        <Route path="/amicaguesthouse/contacts" element={<GuestContacts />} />
        <Route path="/amicarose/overview" element={<AmicaRose />} />
        <Route path="/amicarose/gallery" element={<Gallery />} />
        <Route path="/amicarose/map" element={<Map />} />
        <Route path="/amicarose/contacts" element={<Contacts />} />
        <Route path="/amicarose/" element={<AmicaRose />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      </Routes>
    </>
  );
};

const App = () => (
  <Router>
    <AppContent />
  </Router>
);

export default App;
