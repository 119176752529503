import React from 'react';
import AmicaRoseNavbar from './AmicaRoseNavbar';
import { APIProvider, Map, AdvancedMarker, Pin } from '@vis.gl/react-google-maps';
import './map.css';

const MapComponent = () => {
    const center = { lat: 54.591227475342144, lng: -5.898481273739265 };
    const locations = [
        { key: 'location', location: center },
    ];

    return (
        <Map
            defaultZoom={15}
            defaultCenter={center}
            mapId='619f856ccd52d94d'
            onCameraChanged={(ev) =>
                console.log('camera changed:', ev.detail.center, 'zoom:', ev.detail.zoom)
            }
        >
            {locations.map(poi => (
                <AdvancedMarker key={poi.key} position={poi.location}>
                    <Pin background={'#FBBC04'} glyphColor={'#000'} borderColor={'#000'} />
                </AdvancedMarker>
            ))}
        </Map>
    );
};

const MapPage = () => (
    <APIProvider apiKey={'AIzaSyBug_yCZlXBzv22RnaK2v0YYzusomYODao'} onLoad={() => console.log('Maps API has loaded.')}>
        <div>
            <div className="amica-rose-container">
                <AmicaRoseNavbar />
            </div>
            <div className="map-container">
                <h2>Property Address</h2>
                <p>9 Kingscourt Close, BT6 9AP Belfast</p>
                <p>The Guesthouse has two entrances: the main one at the back, located at 9 Kingscourt Close, and a front entrance on Castlereagh Road, directly opposite Aunt Sandra's Candy Factory at number 60. </p>
                <h2>Parking</h2>
                <p>We recommend using the four spaces at 9 Kingscourt Close near the trees at the bottom left. You may also park anywhere else you prefer, as the area is very safe. Please note that the property is not responsible for parking.</p>
                <a href="/amica/Dir&Park.JPG" target="_blank" rel="noopener noreferrer">
                    <img src="/amica/Dir&Park.JPG" alt="Directions and Parking" style={{ width: '50%' }} />
                </a>
                <h2>Directions</h2>
                <h3>By Bus</h3>
                <p>To reach the guesthouse by bus from Belfast City Centre, take Bus Route 6A heading towards Willowfield. The buses are frequent, departing every 10 minutes throughout the day. After a short ride, alight at the Willowfield stop. From there, it's a brief 6-minute walk to the guesthouse. The bus fare is £2.30 per person. More details available at <a href="https://www.translink.co.uk" target="_blank" rel="noopener noreferrer" className="no-underline-link">Translink</a></p>
                <h3>By Car</h3>
                <p>Drive towards Albertbridge Road. Continue straight until you reach the junction with Castlereagh Road. Turn right onto Castlereagh Road and proceed for a few minutes. Look for the sign indicating the guesthouse's front entrance directly opposite Aunt Sandra's Candy Factory. For the main entrance at the back, continue a bit further, make two consecutive right turns, and then you will find the entrance on Kingscourt Close. The entire drive typically takes about 10 minutes, depending on traffic.</p>
                <h2>Location</h2>
                <div id="map" className="google-map-container">
                    <MapComponent />
                </div>
            </div>
        </div>
    </APIProvider>
);

export default MapPage;
