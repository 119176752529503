import React, { useState } from 'react';
import './slideshow.css';

const Slideshow = ({ images }) => {
  // State to control the modal and which image is shown in both inline and modal slideshows
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Handlers to navigate images in both inline and modal view
  const showNextImage = (e) => {
    e?.stopPropagation(); // Prevent event from closing modal
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const showPrevImage = (e) => {
    e?.stopPropagation();
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="slideshow-container">
      {/* Small inline slideshow with navigation */}
      <div className="inline-slideshow">
        <button className="prev" onClick={showPrevImage}>&#10094;</button>
        <img
          src={images[currentImageIndex].full}
          alt={`Slide ${currentImageIndex + 1}`}
          className="slideshow-thumbnail"
          onClick={openModal}
        />
        <button className="next" onClick={showNextImage}>&#10095;</button>
      </div>

      {/* Modal for full-screen slideshow */}
      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content-wrapper">
            <img src={images[currentImageIndex].full} alt="Full-screen slide" className="modal-content" />
            <span className="close" onClick={closeModal}>×</span>
            <span className="prev" onClick={showPrevImage}>&#10094;</span>
            <span className="next" onClick={showNextImage}>&#10095;</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Slideshow;
