import React, { useState } from 'react';
import './amicaRose.css'; 
import Carousel from './Carousel';
import AmicaRoseNavbar from './AmicaRoseNavbar'; 
import MainImage from './guesthousepics/Suite/main.jpg';
// import Lodgify from './Lodgify';

// note for the future to do: reviews page, link to previous page
const Overview = () => {
  const [carouselOpen, setCarouselOpen] = useState(false);

  const openCarousel = () => {
    setCarouselOpen(true);
  };

  const closeCarousel = () => {
    setCarouselOpen(false);
  };

  return (
    <div>
      <div className="amica-rose-container">
        {carouselOpen && <Carousel closeCarousel={closeCarousel} />}
        <AmicaRoseNavbar />
        <div className="amica-rose-main-image-wrap">
          <img
            src={MainImage}
            alt="Main"
            className="amica-rose-main-image"
            onClick={openCarousel}
          />
          {/* <Lodgify className="amica-rose-lodgify" /> */}
          <button className="amica-rose-view-more" onClick={openCarousel}>
            <svg viewBox="0 0 24 24" className="amica-rose-view-more-icon">
              <path d="M21.58,22.08H2.42a.5.5,0,0,1-.5-.5V2.42a.5.5,0,0,1,.5-.5H21.58a.5.5,0,0,1,.5.5V21.58A.5.5,0,0,1,21.58,22.08Zm-18.66-1H21.08V16.54H2.92Zm1.53-5.54H21.08V13.93L14.91,7.76l-2.42,2.41,1.43,1.43a.5.5,0,0,1,0,.71.51.51,0,0,1-.71,0L10.45,9.54Zm-1.53,0h.16a.65.65,0,0,1,.1-.15l6.91-6.91a.5.5,0,0,1,.71,0l1,1L14.56,6.7a.48.48,0,0,1,.7,0l5.82,5.82V2.92H2.92ZM6.37,8.61a2,2,0,1,1,2-2A2,2,0,0,1,6.37,8.61Zm0-3a1,1,0,1,0,1,1A1,1,0,0,0,6.37,5.65Z" fill="currentColor"></path>
            </svg>
            <span>VIEW MORE PICTURES</span>
          </button>
        </div>
        <div className="amica-rose-down-bar">
          <a href="#description">Description</a>
          <a href="#checkin">Check-in</a>
          <a href="#amenities">Amenities</a>
        </div>
      </div> 
      <div className="amica-rose-fields">
        <div id="description" className="amica-rose-section">
          <h2>Amica Guesthouse</h2>
          <p>Amica Guesthouse is a small guesthouse with 4 rooms, located in East Belfast. Our townhouse is a friendly family run local business, focused on customer needs and affordable prices. The property has been freshly renovated in July 2018 and is based only 20 minutes walking distance from Belfast City Hall and 15 minutes from Belfast Titanic.</p>
          <p>The property features four private rooms, each with its own private entrance and ensuite bathroom.</p> 
          <p>Each room is equipped with a private bathroom, flat screen TV, wi-fi, adjustable room heating, kettle, fridge, 100% cotton linen and towels, as well as a little refreshment which includes tea, coffee and biscuits.</p>
        </div>
        <div id="checkin" className="amica-rose-section">
          <h2>Check-in/Check-out</h2>
          <p>The property is accessible via a secure access code sent to you prior to your arrival. Please note there is no 24h reception at the property, and the property is attended everyday only between 12 noon - 3 pm.</p>
          <p><b>Checkin time</b>: 2.30pm</p>
          <p><b>Checkout time</b>: 11pm</p>
        </div>
        <div id="amenities" className="amica-rose-section">
          <h2>Amenities</h2>
          <p>The property offers complimentary Nescafe coffee, tea, and other amenities (under request) such as iron and iron board.</p>
          <div className="amica-rose-amenities">
            <div className="amica-rose-amenity-list">
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="star">🌟</span>
                <span>Hair dryer</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="soap">🧼</span>
                <span>Cleaning products</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="shampoo">🧴</span>
                <span>Shampoo</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="bathtub">🛁</span>
                <span>Body soap</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="shower">🚿</span>
                <span>Hot water</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="shower gel">🚿</span>
                <span>Shower gel</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="shopping bags">🛍️</span>
                <span>Essentials</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="toilet paper">🧻</span>
                <span>Toilet paper</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="bed">🛏️</span>
                <span>Bed linens</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="lotion">🧴</span>
                <span>Iron</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="sofa">🛋️</span>
                <span>Clothing storage</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="wifi">📶</span>
                <span>Wi-Fi</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="tv">📺</span>
                <span>TV</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="clinking glasses">🥂</span>
                <span>Desk</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="coffee">☕</span>
                <span>Coffee and Tea</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
