import React, { useState } from 'react';
import './amicaRose.css'; 
import Carousel from './Carousel';
import AmicaRoseNavbar from './AmicaRoseNavbar'; 
import MainImage from './amicarosepictures/livingroom/main.jpg';
// import Lodgify from './Lodgify';

// note for the future to do: reviews page, link to previous page
const Overview = () => {
  const [carouselOpen, setCarouselOpen] = useState(false);

  const openCarousel = () => {
    setCarouselOpen(true);
  };

  const closeCarousel = () => {
    setCarouselOpen(false);
  };

  return (
    <div>
      <div className="amica-rose-container">
        {carouselOpen && <Carousel closeCarousel={closeCarousel} />}
        <AmicaRoseNavbar />
        <div className="amica-rose-main-image-wrap">
          <img
            src={MainImage}
            alt="Main"
            className="amica-rose-main-image"
            onClick={openCarousel}
          />
          {/* <Lodgify className="amica-rose-lodgify" /> */}
          <button className="amica-rose-view-more" onClick={openCarousel}>
            <svg viewBox="0 0 24 24" className="amica-rose-view-more-icon">
              <path d="M21.58,22.08H2.42a.5.5,0,0,1-.5-.5V2.42a.5.5,0,0,1,.5-.5H21.58a.5.5,0,0,1,.5.5V21.58A.5.5,0,0,1,21.58,22.08Zm-18.66-1H21.08V16.54H2.92Zm1.53-5.54H21.08V13.93L14.91,7.76l-2.42,2.41,1.43,1.43a.5.5,0,0,1,0,.71.51.51,0,0,1-.71,0L10.45,9.54Zm-1.53,0h.16a.65.65,0,0,1,.1-.15l6.91-6.91a.5.5,0,0,1,.71,0l1,1L14.56,6.7a.48.48,0,0,1,.7,0l5.82,5.82V2.92H2.92ZM6.37,8.61a2,2,0,1,1,2-2A2,2,0,0,1,6.37,8.61Zm0-3a1,1,0,1,0,1,1A1,1,0,0,0,6.37,5.65Z" fill="currentColor"></path>
            </svg>
            <span>VIEW MORE PICTURES</span>
          </button>
        </div>
        <div className="amica-rose-down-bar">
          <a href="#description">Description</a>
          <a href="#rooms">Rooms</a>
          <a href="#checkin">Check-in</a>
          <a href="#amenities">Amenities</a>
        </div>
      </div> 
      <div className="amica-rose-fields">
        <div id="description" className="amica-rose-section">
          <h2>Amica Rose</h2>
          <p>Welcome to 86 Rosebery Road, your perfect home away from home. The property has been recently renewed and refurbished to accommodate the needs of any type of guest.</p>
          <p>The place is very close to Ormeau Park, a beautiful and expansive green space perfect for leisurely walks, picnics, and outdoor activities. The Botanic Gardens, another lovely spot for nature lovers, are just a 25-minute walk away. The Titanic Quarter, home to the famous Titanic Belfast museum and other attractions, is a 30-minute walk from the property. The city center is easily accessible with a 20-minute walk or a 5-minute drive. Parking is on the street in a very quiet neighborhood.</p>
        </div>
        <div id="rooms" className="amica-rose-section">
          <h2>Rooms</h2>
          <p>The property features 2 bedrooms, a living room with a dining table, a fully equipped kitchen, and a TV in every room. There is a small backyard furnished for guests to smoke or enjoy tea/coffee outside. </p> 
          <p>The bedrooms are fully equipped with 100% cotton sheets, duvet covers, towels, hand and body wash, a vanity kit, and a hairdryer. The entire space is covered by Wi-Fi.</p>
          <p><b>Breakfast</b> is available under request when booking the property.</p>
        </div>
        <div id="checkin" className="amica-rose-section">
          <h2>Check-in/Check-out</h2>
          <p>The first check-in is available only through an access code. Inside, guests will find 2 cards to be used for entry. They can leave these cards on the table without any further communication once they have checked out.</p>
          <p><b>Checkin time</b>: 3pm</p>
          <p><b>Checkout time</b>: 11pm</p>
        </div>
        <div id="amenities" className="amica-rose-section">
          <h2>Amenities</h2>
          <p>The house offers complimentary Lavazza coffee, tea, bottled water, and other amenities such as iron and iron board.</p>
          <div className="amica-rose-amenities">
            <div className="amica-rose-amenity-list">
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="star">🌟</span>
                <span>Hair dryer</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="soap">🧼</span>
                <span>Cleaning products</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="shampoo">🧴</span>
                <span>Shampoo</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="water drop">💧</span>
                <span>Conditioner</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="bathtub">🛁</span>
                <span>Body soap</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="shower">🚿</span>
                <span>Hot water</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="shower gel">🚿</span>
                <span>Shower gel</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="shopping bags">🛍️</span>
                <span>Essentials</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="toilet paper">🧻</span>
                <span>Toilet paper</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="bed">🛏️</span>
                <span>Bed linens</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="bed">🛏️</span>
                <span>Extra pillows and blankets</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="lotion">🧴</span>
                <span>Iron</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="sofa">🛋️</span>
                <span>Clothing storage</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="wifi">📶</span>
                <span>Wi-Fi</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="tv">📺</span>
                <span>TV</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="clinking glasses">🥂</span>
                <span>Dedicated workspace</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="coffee">☕</span>
                <span>Lavazza Coffee</span>
              </div>
              <div className="amica-rose-amenity">
                <span className="icon" role="img" aria-label="cigarette">🚬</span>
                <span>Outdoor smoking area</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
