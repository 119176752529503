import React from 'react';
import './rooms.css';
import AmicaRoseNavbar from './AmicaRoseNavbar'; 
import Images from './guesthousepics/Images';
import Slideshow from './Slideshow';

const roomDetails = {
  suite: {
    name: "Suite",
    description: "The ultimate suite with luxurious amenities and spacious living area.",
    amenities: [
      { name: "Hair dryer", icon: "💇" },
      { name: "TV", icon: "📺" },
      { name: "Bathtub/shower combination", icon: "🛁" },
      { name: "Coffee/Tea maker", icon: "☕" },
      { name: "Desk with lamp", icon: "💡" },
      { name: "Full kitchen", icon: "🍽️" },
      { name: "Private bathroom", icon: "🚽" },
      { name: "Sofa bed", icon: "🛋️" },
      { name: "Wifi connection", icon: "📶" },
      { name: "Tables and chairs", icon: "🪑" },
      { name: "Smart TV", icon: "🖥️" },
    ],
  },
  bella: {
    name: "Bella",
    description: "A spacious room with beautiful views and elegant decor.",
    amenities: [
      { name: "Hair dryer", icon: "💇" },
      { name: "TV", icon: "📺" },
      { name: "Coffee/Tea maker", icon: "☕" },
      { name: "Desk with lamp", icon: "💡" },
      { name: "Wifi connection", icon: "📶" },
      { name: "Pets allowed", icon: "🐾" },
      { name: "Shower", icon: "🚿" },
      { name: "Accessible room", icon: "♿" },
      { name: "Toiletries", icon: "🧴" },
      { name: "Mini refrigerator", icon: "❄️" },
      { name: "Free Wifi", icon: "📶" },
      { name: "Heating", icon: "🔥" },
    ],
  },
  king: {
    name: "King",
    description: "Luxurious room with a king-sized bed, ideal for a relaxing stay.",
    amenities: [
      { name: "Hair dryer", icon: "💇" },
      { name: "TV", icon: "📺" },
      { name: "Coffee/Tea maker", icon: "☕" },
      { name: "Private bathroom", icon: "🚽" },
      { name: "Shower only", icon: "🚿" },
      { name: "Wifi connection", icon: "📶" },
      { name: "Heating", icon: "🔥" },
    ],
  },
  double: {
    name: "Double",
    description: "Perfect for couples or solo travelers looking for comfort and convenience.",
    amenities: [
      { name: "Internet", icon: "🌐" },
      { name: "Hair dryer", icon: "💇" },
      { name: "TV", icon: "📺" },
      { name: "Coffee/Tea maker", icon: "☕" },
      { name: "Desk with lamp", icon: "💡" },
      { name: "Private bathroom", icon: "🚽" },
      { name: "Shower only", icon: "🚿" },
      { name: "Wifi connection", icon: "📶" },
      { name: "Heating", icon: "🔥" },
    ],
  },
};

const Rooms = () => {
  return (
    <div>
      <div className="amica-rose-container">
        <AmicaRoseNavbar />
      </div>
      <div className="gallery-container">
        <h1 className="gallery-title">Our Rooms</h1>
        <div className="gallery-category-container">
          {Object.keys(roomDetails).map((key) => (
            <div key={key} className="gallery-category">
              <h2 className="room-name">{roomDetails[key].name}</h2>
              <div className="room-section">
                <div className="room-details">
                  <p className="room-description">{roomDetails[key].description}</p>
                  <ul className="amenities-list">
                    {roomDetails[key].amenities.map((amenity, index) => (
                      <li key={index} className="amenity-item">
                        <span className="amenity-icon">{amenity.icon}</span>
                        <span className="amenity-name">{amenity.name}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="room-slideshow">
                  <Slideshow images={Object.values(Images[key])} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Rooms;
